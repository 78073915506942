const setCookie = (name, value, days = 30, path = '/') => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '');
};

const deleteCookie = (name, path) => {
    setCookie(name, '', -1, path);
};

const cookieData = $('#cookie-data'),
    acceptTranslation = cookieData.data('acceptCookiesTranslation'),
    modalText = cookieData.data('cookiesModalTextTranslation'),
    modalTitle = cookieData.data('cookiesModalTitleTranslation');


const addStyle = (() => {
    const style = document.createElement('style');
    document.head.append(style);
    return (styleString) => style.textContent = styleString;
})();

const createCookieModal = () => {
    addStyle(`
        #gdpr-cookies.modal {
          pointer-events: none;
          }
          body.modal-open {
          overflow: initial;
          }
        .modal-backdrop {
          display: none;
          }
    `)
    return `
        <div class="modal fade" id="gdpr-cookies" 
             tabindex="-1" role="dialog" 
             aria-labelledby="cookieModalLabel"
             data-keyboard="false"
             data-backdrop="static"
             aria-hidden="true">
          <div class="modal-dialog mb-4 fixed-bottom pr-0 shadow-lg" role="document" style="max-width: 50%">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="cookieModalLabel">${modalTitle}</h5>
                    <div id="languageDiv">
                    </div>
                </div>
                <div class="modal-body">
                <div class="notice row">
                  <div class="col-12 col-lg-8"><p>${modalText}</p></div>
                  <div class="col-lg-4 col-12 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end">
                    <button class="btn btn-light mx-2" id="accept-cookies"
                            type="button" data-dismiss="modal">${acceptTranslation}</button>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
         `;
};

const renderAndFunctionsCookieModal = () => {
    const renderedCookieModal = createCookieModal();

    cookieData.after(renderedCookieModal);

    const gdprModal = $('#gdpr-cookies'),
        acceptCookieBtn = $('#accept-cookies');

    gdprModal.on('shown.bs.modal', function (){
        $(document).off('focusin.modal');
    });
    gdprModal.modal('show');
    $(".modal:not(#gdpr-cookies)").on('shown.bs.modal', function () {
        addStyle(`
        #gdpr-cookies.modal {
          pointer-events: initial;
          }
          body.modal-open {
          overflow: hidden;
          }
        .modal-backdrop {
          display: initial;
          }
    `)
    })
    $(".modal:not(#gdpr-cookies)").on('hidden.bs.modal', function () {
        addStyle(`
        #gdpr-cookies.modal {
          pointer-events: none;
          }
          body.modal-open {
          overflow: initial;
          }
        .modal-backdrop {
          display: none;
          }
    `)
    });

    $('#languageDiv').html($('#header-item-language').parent().clone());

    acceptCookieBtn.on('click', function () {
        setCookie('edenland-gdpr-cookies', '1', 60);
        gdprModal
            .modal('hide')
            .destroy();
    });
}

if (getCookie('edenland-gdpr-cookies') !== '1') renderAndFunctionsCookieModal();